import ActionModalMixin from "./ActionModalMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import Api from "@/apis/Api";

const ChangeEmailAddressMixin = {
  mixins: [ActionModalMixin, FormRulesMixin],
  methods: {
    showChangeAddressEmailModal() {
      this.resetModal();
      this.modalOptions.title = this.$t('heading.accountPage.modal.changeEmail.title');
      this.modalOptions.icon = "$edit";
      this.modalOptions.buttons.unshift({
        label: this.$t('button.emailAddress.change'),
        color: "primary",
        onclick: (modal) => {
          modal.$refs.form.validate() &&
            this.changeAddressEmail(modal.formValues);
        },
      });
      this.modalOptions.formFields = [
        {
          label: this.$t('form.label.passwordCurrent'),
          name: "password",
          type: "password",
          showPassword: false,
          rules: this.adminPasswordRules,
        },
        {
          label: this.$t('form.label.emailAddress'),
          name: "email",
          type: "email",
          rules: this.emailRules,
        },
      ];
      this.modalOptions.open = true;
    },
    changeAddressEmail(formData) {
      Api.put(`/user/edit/email`, formData)
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.account.changeEmail.success'),
          });
          this.modalOptions.open = false;
          this.reloadMyAccount();
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: error.response.data.errors?.email?.length
              ? error.response.data.errors.email[0]
              : error.response.data.message,
          });
        });
    },
  },
};
export default ChangeEmailAddressMixin;
