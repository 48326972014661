import Api from "../apis/Api"
import ActionModalMixin from "./ActionModalMixin"


const BackupActionsMixin = {
    mixins: [ActionModalMixin],
    data: function () {
        return {
            modalOptions: {},

        }
    },
    methods: {
        showMassForgetDeviceModal(devices) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.accountPage.modal.device.forget.title', {count: devices.length});
            this.modalOptions.message = this.$t('heading.accountPage.modal.device.forget.info', {count: devices.length});
            this.modalOptions.color = "error";
            this.modalOptions.icon = "$alertwarning";
            
            this.modalOptions.formFields = [
                {
                    message: `<b>${this.$t('message.confirmAction')}</b>`,
                    label: this.$t('form.confirmDelete.forgetDevice', {count: devices.length}),
                    name: "confirm",
                    type: "checkbox",
                    required: true,
                },
            ];

            this.modalOptions.buttons.unshift({
                label: this.$t('button.device.forget', {count: devices.length}),
                color: "error",
                onclick: () => {
                    this.massForgetDevice(devices);
                },
            });
            this.modalOptions.open = true;
        },
        showForgetDeviceModal(device) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.accountPage.modal.device.forget.title', {count: 1});
            this.modalOptions.message = this.$t('heading.accountPage.modal.device.forget.info', {
                count: 1,
                ip: `<b>${device.ip_address}</b>`
            });
            this.modalOptions.color = "error";
            this.modalOptions.icon = "$alertwarning";
            this.modalOptions.formFields = [
                {
                    message: `<b>${this.$t('message.confirmAction')}</b>`,
                    label: this.$t('form.confirmDelete.forgetDevice', {count: 1}),
                    name: "confirm",
                    type: "checkbox",
                    required: true,
                },
            ];
            this.modalOptions.buttons.unshift({
                label: this.$t('button.device.forget', {count: 1}),
                color: "error",
                onclick: () => {
                    this.forgetDevice(device);
                },
            });
            this.modalOptions.item = device;
            this.modalOptions.open = true;
        },
        massForgetDevice(devices) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Promise.all(devices.map(device => {
                return Api.post(`/user/device/forget`, {deviceId: device.device_id})
            })).then((response) => {
                let isLogout = false;
                response.forEach((response) => {
                    if (response.status === 204) {
                        isLogout = true;
                    }
                })
                if (isLogout) {
                    this.$router.push({ path: "/login" });
                } else {
                    this.$store.dispatch("addAlert", {
                        success: true,
                        successMessage: this.$t('notification.account.device.forget.success', {count: devices.length})
                    });
                    this.reloadData();
                }
            })
                .catch((error) => {
                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: Api.getErrorMessage(error),
                    });
                })
                .finally(() => {
                    this.modalClose();
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },
        forgetDevice(device) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Api.post(`/user/device/forget`, {deviceId: device.device_id})
                .then((response) => {
                    if (response.status === 200) {
                        this.$store.dispatch("addAlert", {
                            success: true,
                            successMessage: this.$t('notification.account.device.forget.success', {count: 1})
                        });

                        this.reloadData();
                    } else {
                        this.$router.push({ path: "/login" });
                    }
                })
                .catch((error) => {
                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: Api.getErrorMessage(error),
                    });
                })
                .finally(() => {
                    this.modalClose();
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },
        showLogoutDeviceModal(device) {
            this.resetModal();
            this.modalOptions.title = this.$t('heading.accountPage.modal.device.logout.title');
            this.modalOptions.message = this.$t('heading.accountPage.modal.device.logout.info', {ip: `<b>${device.ip_address}</b>`});
            this.modalOptions.color = "error";
            this.modalOptions.icon = "$alertwarning";
            this.modalOptions.formFields = [
                {
                    message: `<b>${this.$t('message.confirmAction')}</b>`,
                    label: this.$t('form.confirmDelete.logoutDevice'),
                    name: "confirm",
                    type: "checkbox",
                    required: true,
                },
            ];
            this.modalOptions.buttons.unshift({
                label: this.$t('button.logout'),
                color: "error",
                onclick: () => {
                    this.logoutDevice(device);
                },
            });
            this.modalOptions.item = device;
            this.modalOptions.open = true;
        },
        logoutDevice(device) {
            this.modalOptions.persistent = true;
            this.modalOptions.submitting = true;
            this.modalOptions.submittingSuccess = "";
            this.modalOptions.submittingError = "";

            Api.post(`/user/device/logout`, { session_id: device.session_id })
                .then((response) => {
                    if (response.status === 200) {
                        this.$store.dispatch("addAlert", {
                            success: true,
                            successMessage: "Device has been logged off",
                        });
                        this.reloadData();
                    } else {
                        this.$router.push({ path: "/login" });
                    }
                })
                .catch((error) => {
                    this.$store.dispatch("addAlert", {
                        success: false,
                        errorMessage: Api.getErrorMessage(error),
                    });
                })
                .finally(() => {
                    this.modalClose();
                    this.modalOptions.open = false;
                    this.modalOptions.persistent = false;
                    this.modalOptions.submitting = false;
                });
        },
    }
}

export default BackupActionsMixin