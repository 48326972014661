<template>
  <v-card class="basic-modal" elevation="4">
    <v-card-title class="d-flex justify-space-between">
      <h5 class="error--text">
        <v-icon class="mr-4" size="24" color="error"> $alertwarning </v-icon>
        {{ $t('heading.accountPage.modal.disable2fa.title') }}
      </h5>
    </v-card-title>
    <div>
      <v-card-text>
        <p class="">{{ $t('heading.accountPage.modal.disable2fa.info') }}</p>
      </v-card-text>
      <v-card-actions class="d-flex flex-wrap pb-8">
        <div class="w-100 pb-2">
          <v-btn color="error" x-large block elevation="0" @click="deactivate"
            >{{ $t('button.confirm') }}</v-btn>
        </div>
        <div class="w-100 mt-2">
          <v-btn
            x-large
            block
            text
            color="normal"
            elevation="0"
            @click="closeModal(true)"
            >{{ $t('button.back') }}</v-btn>
        </div>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import Api from "@/apis/Api";

export default {
  data() {
    return {
      password: "",
      showPassword: false,
      confirmedPassword: true,
    };
  },
  methods: {
    deactivate() {
      Api.delete("/user/two-factor-authentication")
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.account.disable2fa.success'),
          });
          this.$store.state.home.user.two_factor_auth_enabled = false;
          this.closeModal(false);
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: error.response.data.message,
          });
        });
    },
    confirmPassword() {
      Api.post("/user/confirm-password", { password: this.password })
        .then(() => {
          this.deactivate();
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: error.response.data.message,
          });
        });
    },
    closeModal(status) {
      this.$emit("modal-close", status);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    .v-card__actions {
      padding-top: 16px;
    }

    .v-card__title {
      min-height: 96px;
    }

    .v-card__text {
      -webkit-line-clamp: initial;
    }
  }
}

.checkbox::v-deep {
  .v-input--checkbox {
    .v-input__slot {
      max-height: 24px;
      min-height: unset;
    }
  }
}

.v-input::v-deep {
  .v-text-field__details {
    margin-top: 2px;
    margin-bottom: 0px !important;
  }
}

b {
  font-weight: $font-weight-semibold;
  color: map-get($text, heading);
}

.checkbox-label {
  cursor: pointer;
}

.input-group {
  .v-input + .v-input {
    margin-left: 16px;
  }
}

.v-form .last-child {
  .v-input {
    &.v-text-field {
      margin-bottom: 0px;
    }
  }
}

.v-form .v-input::v-deep.v-text-field {
  margin-bottom: 16px;
}

.v-card::v-deep {
  .v-card__title {
    flex-wrap: nowrap;

    .v-btn {
      align-self: flex-start;
    }
  }
}
</style>