<template>
  <tr>
    <td class="w-100">
      <div class="mobile-table-item">
        <div class="mobile-table-item__select">
          <checkbox @change="$emit('update:checked')" :checked="checked" />
        </div>
        <div class="mobile-table-item__values">
          <div class="mobile-table-item__group">
            <div class="mobile-table-item__row">
              <div
                class="mobile-table-item__header"
                @click="$emit('update:sortBy', headers[1])"
              >
                {{ $t('form.label.ip') }}
              </div>
              <div class="mobile-table-item__value">
                <span class="bold p-3">{{ item.ip_address }}</span>
              </div>
            </div>
            <div class="mobile-table-item__row">
              <div
                class="mobile-table-item__header"
                @click="$emit('update:sortBy', headers[2])"
              >
                {{ $t('form.label.browser') }}
              </div>
              <div class="mobile-table-item__value">
                <span class="p-3">
                  <div class="d-flex align-center">
                    <v-icon size="24" class="mr-2">
                      {{ `$${itemBrowser}` }}
                    </v-icon>
                    {{ item.browser }}
                  </div></span
                >
              </div>
            </div>
          </div>
          <div class="mobile-table-item__group">
            <div class="mobile-table-item__row">
              <div
                class="mobile-table-item__header"
                @click="$emit('update:sortBy', headers[3])"
              >
                {{ $t('form.label.device') }}
              </div>
              <div class="mobile-table-item__value">
                <span class="p-3">
                  <v-icon size="24" class="mr-2">
                    {{ `$${itemDevice}` }}
                  </v-icon>
                  {{ item.device }}
                  <status-label
                    class="ml-2"
                    v-if="item.current_device"
                    small
                    status="primary"
                    value="Current Device"
                /></span>
              </div>
            </div>
            <div class="mobile-table-item__row">
              <div
                class="mobile-table-item__header"
                @click="$emit('update:sortBy', headers[4])"
              >
                Last Login
              </div>
              <div class="mobile-table-item__value">
                {{ item.last_login }}
              </div>
            </div>
          </div>
          <div class="mobile-table-item__group">
            <div class="mobile-table-item__actions">
              <v-tooltip
                v-if="item.device_id"
                transition="custom-tooltip"
                open-delay="150"
                bottom
                z-index="99"
                offset-overflow
                nudge-bottom="4px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    small
                    @click="$emit('forgetDevice')"
                  >
                    <v-icon>$thrash</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('button.device.forget', {count: 1}) }}</span>
              </v-tooltip>
              <v-tooltip
                v-if="item.session_id"
                transition="custom-tooltip"
                open-delay="150"
                bottom
                z-index="99"
                offset-overflow
                nudge-bottom="4px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    @click="$emit('logoutDevice')"
                  >
                    <v-icon>$logout</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('button.device.logout') }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import Checkbox from "../../buttons/Checkbox.vue";
import StatusLabel from "./../../StatusLabel.vue";

export default {
  components: {
    Checkbox,
    StatusLabel,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    currentDevice: Boolean,
    checked: Boolean,
    index: {
      type: Number,
    },
    item: Object,
    itemDevice: String,
    itemBrowser: String,
  },
};
</script>

<style lang="scss" scoped>
td,
tr {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.checkbox::v-deep {
  .v-input .v-input__control .v-input__slot {
    min-height: unset !important;
  }
}
</style>
