<template>
    <div  class="mobile-item">
      <div class="pb-3">
        <v-skeleton-loader type="text" width="25%" />
        <v-skeleton-loader type="text" width="50%" />
      </div>
      <div class="pb-3">
        <v-skeleton-loader type="text" width="25%" />
        <v-skeleton-loader type="text" width="30%" />
      </div>
      <div class="pb-3">
        <v-skeleton-loader type="text" width="25%" />
        <v-skeleton-loader type="text" width="10%" />
      </div>
      <div class="pb-3">
        <v-skeleton-loader type="text" width="25%" />
        <v-skeleton-loader type="text" width="15%" />
      </div>
      <div class="actions-container d-flex justify-start align-center">
        <v-skeleton-loader class="actions" type="avatar@2" />
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="scss" scoped>
  .checkbox-container {
    width: 46px;
  }
  .text-container {
    width: calc((100% - 280px) / 5);
  }
  .v-skeleton-loader::v-deep {
    &.checkbox {
      .v-skeleton-loader__chip {
        width: 22px;
        height: 22px;
        position: relative;
        border-radius: 4px;
        background-color: #fff;
      }
    }
    &.avatar {
      .v-skeleton-loader__avatar {
        width: 40px;
        height: 40px;
      }
    }
    &.names {
      width: 240px;
      .v-skeleton-loader__text {
        width: 205px;
        height: 10px;
        border-radius: 3px;
        margin-top: 4px;
      }
      .v-skeleton-loader__heading {
        height: 24px;
        width: 143px;
        border-radius: 3px;
      }
    }
    &.text {
      .v-skeleton-loader__text {
        width: 80px;
      }
    }
    &.tags {
      display: flex;
      width: 299px;
      .v-skeleton-loader__chip {
        margin-right: 8px;
        width: 68px;
        height: 20px;
      }
    }
    &.actions {
      width: 72px;
      .v-skeleton-loader__avatar {
        height: 38px;
        width: 38px;
        margin-left: 34px;
      }
    }
  }
  
  .mobile-item {
    border-bottom: 1px solid map-get($gray, lighten2);
    padding: 12px 36px;
    margin-left: -24px;
    width: calc(100% + 36px);
    .v-skeleton-loader::v-deep {
      &.actions {
        display: flex;
        flex-direction: row;
        .v-skeleton-loader__avatar {
          height: 24px;
          width: 24px;
          margin-left: 16px;
          &:first-child {
            margin-left: 0px !important;
          }
        }
      }
    }
  }
  </style>