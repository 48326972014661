<template>
  <v-card class="basic-modal" elevation="4" width="560px">
    <v-card-title class="d-flex justify-space-between">
      <h5 class="">
        <v-icon size="24" class="mr-4" color="primary">$lockoutline </v-icon>
        {{ $t('heading.accountPage.modal.2fa.firstStep.title') }}
      </h5>

      <v-btn icon x-small @click="$emit('modal-close')">
        <v-icon size="24">$close</v-icon></v-btn
      >
    </v-card-title>

    <div v-if="step === 1">
      <v-form class="d-flex flex-column" @submit.prevent lazy-validation>
        <v-card-text>
          <div v-html="codeQr" class="d-flex justify-center mb-8"></div>

          <p class="text-center p-1">
            <span>{{ $t('heading.accountPage.modal.2fa.firstStep.info') }}</span>
            <b class="primary--text text--capitalize ms-2">{{ secretKey }}</b>
          </p>

          <div>
            <label class="base--text p-3">
              {{ $t('form.label.code') }}
              <form-help-icon
                :text="
                  $t('tooltip.two_factor_authentication.confirmation_code')
                "
              />
            </label>
            <v-text-field
              v-model="code"
              outlined
              dense
              hide-details="auto"
              class="v-input--md"
              type="text"
              :autofocus="false"
              :placeholder="$t('form.placeholder.code')"
              persistent-placeholder
              persistent-hint
              @keydown.enter="activate"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap pb-4">
          <div class="w-100 pb-2">
            <v-btn
              color="primary"
              x-large
              block
              elevation="0"
              @click="activate"
              :disabled="code.length !== 6"
              rules
              >{{ $t('button.confirm') }}</v-btn>
          </div>
          <div class="w-100">
            <v-btn
              x-large
              block
              text
              elevation="0"
              class="gray--text text--darken-1"
              @click.prevent="closeModal(false)"
            >
              <span class="font-weight-light">{{ $t('button.back') }}</span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </div>

    <div v-else-if="step === 2">
      <v-card-text class="pb-4">
        <p class="p-1">{{ $t('heading.accountPage.modal.2fa.secondStep.info') }}</p>
        <div class="recovery-code-box">
          {{ recoveryCode[0] }}

          <copy-text-button
            :text="recoveryCode[0]"
            :color="'primary'"
            :size="24"
          />
        </div>

        <div
          class="alert alert--error alert--outlined mt-4"
          style="padding: 10px"
        >
          <div class="alert__icon-box">
            <v-icon color="error" size="20">$alertwarning</v-icon>
          </div>
          <div class="alert__text-box alert__text-box--wide">
            <!-- <p class="p-2 font-weight-bold mb-3">Error Message</p> -->

            <p class="mb-0 p-3 text-gray-darken-1">
              <template>
                {{ $t('heading.accountPage.modal.2fa.secondStep.alert') }}
              </template>
            </p>
          </div>
        </div>

        <div class="mt-2">
          <h4 class="p-2 font-weight-600">
            {{ $t('message.confirmAction') }}
          </h4>
          <label class="base--text checkbox-label d-flex align-center p-2">
            <checkbox
              class="mt-4"
              outlined
              :label="$t('heading.accountPage.modal.2fa.secondStep.confirmationCheckbox')"
              color="primary"
              dense
              @change="confirmCheckbox"
            ></checkbox>
          </label>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-wrap pb-8">
        <div class="w-100">
          <v-btn
            color="primary"
            x-large
            block
            elevation="0"
            @click="closeModal(true)"
            :disabled="!confirmedCloseModal"
            >{{ $t('button.account.back') }}
          </v-btn>
        </div>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import Api from "@/apis/Api";
import checkbox from "@/components/buttons/Checkbox.vue";
import CopyTextButton from "../buttons/CopyTextButton.vue";
import formHelpIcon from "../FormHelpIcon.vue";

export default {
  components: {
    checkbox,
    CopyTextButton,
    formHelpIcon,
  },
  props: ["codeQr", "secretKey", "confirmedPassword"],
  data() {
    return {
      step: 1,
      code: "",
      recoveryCode: ["werwersdf"],
      password: "",
      showPassword: false,
      loading: false,
      confirmedCloseModal: false,
    };
  },
  methods: {
    activate() {
      Api.post("/user/confirmed-two-factor-authentication", { code: this.code })
        .then(() => {
          Api.get("/user/two-factor-recovery-codes")
            .then((response) => {
              this.$store.dispatch("addAlert", {
                success: true,
                successMessage: this.$t('notification.account.2fa.success'),
              });
              this.$emit("enable-2fa", true);
              this.recoveryCode = response.data;
              this.confirmedCloseModal = false;
              this.step = 2;
            })
            .catch((error) => {
              this.$store.dispatch("addAlert", {
                success: false,
                errorMessage: error.response.data.message,
              });
              this.$emit("enable-2fa", false);
            });
        })
        .catch(() => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.$t('notification.account.2fa.error'),
          });
          this.$emit("enable-2fa", false);
        });
    },
    // confirmPassword() {
    //   this.loading = true;
    //   Api.post("/user/confirm-password", { password: this.password })
    //     .then(() => {
    //       this.$store.dispatch("addAlert", {
    //         success: true,
    //         successMessage: "Password has been confirmed",
    //       });
    //       this.enableTwoFactorAuth();
    //     })
    //     .catch((error) => {
    //       this.$store.dispatch("addAlert", {
    //         success: false,
    //         errorMessage: error.response.data.message,
    //       });
    //     });
    // },
    enableTwoFactorAuth() {
      Api.post("/user/two-factor-authentication")
        .then(() => {
          Api.get("/user/two-factor-qr-code")
            .then((response) => {
              this.codeQr = response.data.svg;
              Api.get("/user/two-factor-secret-key")
                .then((response) => {
                  this.secretKey = response.data.secretKey;
                  this.loading = false;
                  this.confirmedPassword = true;
                })
                .catch((error) => {
                  this.$store.dispatch("addAlert", {
                    success: false,
                    errorMessage: error.response.data.message,
                  });
                });
            })
            .catch((error) => {
              this.$store.dispatch("addAlert", {
                success: false,
                errorMessage: error.response.data.message,
              });
            });
        })
        .catch((error) => {
          if (error.response.status === 423) {
            this.password = "";
            this.confirmedPassword = false;
          } else {
            this.$store.dispatch("addAlert", {
              success: false,
              errorMessage: error.response.data.message,
            });
          }
        });
    },
    closeModal(status) {
      this.$emit("modal-close", status);
      this.step = 1;
      this.code = "";
      this.password = "";
    },
    confirmCheckbox() {
      this.confirmedCloseModal = !this.confirmedCloseModal;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    .v-card__actions {
      padding-top: 16px;
    }

    .v-card__title {
      min-height: 96px;
    }

    .v-card__text {
      font-weight: $font-weight-light;
      -webkit-line-clamp: initial;
    }
  }
}

.checkbox::v-deep {
  .v-input--checkbox {
    .v-input__slot {
      max-height: 24px;
      min-height: unset;
    }
  }
}

label + .v-input {
  margin-top: 10px;
}

.recovery-code-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: var(--v-primary-base);
  background: rgba(7, 192, 126, 0.05);
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

.v-input::v-deep {
  .v-text-field__details {
    margin-top: 2px;
    margin-bottom: 0px !important;
  }
}

b {
  font-weight: $font-weight-semibold;
  color: map-get($text, heading);
}

.checkbox-label {
  cursor: pointer;
}

.input-group {
  .v-input + .v-input {
    margin-left: 16px;
  }
}

.v-form .last-child {
  .v-input {
    &.v-text-field {
      margin-bottom: 0px;
    }
  }
}

.v-form .v-input::v-deep.v-text-field {
  margin-bottom: 16px;
}

.v-card::v-deep {
  .v-card__title {
    flex-wrap: nowrap;

    .v-btn {
      align-self: flex-start;
    }
  }
}
</style>
