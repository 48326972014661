import ActionModalMixin from "./ActionModalMixin";
import Api from "@/apis/Api";

const ChangePasswordMixin = {
	mixins: [ActionModalMixin],
	methods: {
		showChangePasswordModal() {
			this.resetModal();
			this.modalOptions.title = this.$t('heading.accountPage.modal.changePassword.title');
			this.modalOptions.icon = "$lock";
			this.modalOptions.buttons.unshift({
				label: this.$t('button.password.change'),
				color: "primary",
				onclick: (modal) => {
					modal.$refs.form.validate() && this.changePassword(modal.formValues);
				},
			});
			this.modalOptions.formFields = [
				{
					label: this.$t('form.label.passwordCurrent'),
					name: "current_password",
					rules: [
						(v) => !!v || this.$t('form.validation.required', {
							field: this.$t('form.label.passwordCurrent')
						}),
						(v) =>
							(v && v.length >= 6) ||
							this.$t('form.validation.minLength', {
								field: this.$t('form.label.passwordCurrent'),
								length: 6
							}),
						(v) =>
							(v && v.length <= 25) ||
							this.$t('form.validation.maxLength', {
								field: this.$t('form.label.passwordCurrent'),
								length: 25
							}),
					],
					type: "password",
					showPassword: false,
					required: true,
				},
				{
					label: this.$t('form.label.passwordNew'),
					name: "password",
					type: "password",
					rules: [
						(v) => !!v || this.$t('form.validation.required', {
							field: this.$t('form.label.passwordNew')
						}),
						(v) =>
							(v && v.length >= 6) ||
							this.$t('form.validation.minLength', {
								field: this.$t('form.label.passwordNew'),
								length: 6
							}),
						(v) =>
							(v && v.length <= 25) ||
							this.$t('form.validation.maxLength', {
								field: this.$t('form.label.passwordNew'),
								length: 25
							}),
					],
					showPassword: false,
					required: true,
				},
				{
					label: this.$t('form.label.passwordConfirm'),
					name: "password_confirmation",
					rules: [
						(v) => !!v || this.$t('form.validation.required', {
							field: this.$t('form.label.passwordConfirm')
						}),
						(v) =>
							(!!v &&
								!!this.$refs.myAccountModal.formValues.password &&
								v === this.$refs.myAccountModal.formValues.password) ||
							this.$t('form.validation.notSame', {
								first_field: this.$t('form.label.passwordConfirm'),
								second_field: this.$t('form.label.passwordNew')
							}),
					],
					type: "password",
					showPassword: false,
				},
			];
			this.modalOptions.open = true;
		},
		changePassword(formData) {
			if (formData.password === formData.password_confirmation) {
				Api.put("/user/password", formData)
					.then(() => {
						this.$store.dispatch("addAlert", {
							success: true,
							successMessage: this.$t('notification.account.changePassword.success'),
						});
						this.modalOptions.open = false;
					})
					.catch((error) => {
						this.$store.dispatch("addAlert", {
							success: false,
							errorMessage: error.response.data.message,
						});
					});
			} else {
				this.$store.dispatch("addAlert", {
					success: false,
					errorMessage: this.$t('notification.account.changePassword.error'),
				});
			}
		},
	},
};
export default ChangePasswordMixin;
